// formkit.config.ts
import {nl} from '@formkit/i18n';
import {createMultiStepPlugin} from '@formkit/addons';
import {createInput, type DefaultConfigOptions} from '@formkit/vue';
import {generateClasses} from '@formkit/themes';
import {useDateHelper} from '#utils/date';
import {addAsteriskPlugin} from '#utils/formkit/asterisk-plugin';
import {formKitIcons} from '#utils/icon';
import {FormkitAddress} from '#components';
import {FormkitDate} from '#components';
import type {FormKitNode} from '@formkit/core';
import {createProPlugin, repeater} from '@formkit/pro';
import '@formkit/addons/css/multistep';

const boxInput = {
  wrapper: 'flex',
  input: '$reset appearance-none h-4 w-4 mr-2.5 border border-black-900 bg-white focus:ring-0 transition duration-200 hover:bg-transparent checked:focus:bg-primary checked:hover:bg-primary text-primary checked:border-black-900',
  label: '$reset',
  legend: '$reset text-sm mb-1',
  inner: 'flex items-center',
};

const config: DefaultConfigOptions = {
  plugins: [
    addAsteriskPlugin,
    createMultiStepPlugin(),
    createProPlugin('fk-cc1ce329d1', {
      repeater,
    }),
  ],
  locales: {nl},
  locale: 'nl',
  icons: {
    ...formKitIcons,
  },
  inputs: {
    address: createInput(FormkitAddress),
    datepicker: createInput(FormkitDate, {
      family: 'date',
    }),
  },
  config: {
    classes: generateClasses({
      global: {
        help: 'text-xs',
        outer: 'formkit-disabled:opacity-80 w-full',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'text-secondary',
        label: 'block text-black-800 text-sm ml-1.5 translate-y-1/2 bg-white px-0.5 inline-block z-10 relative',
        inner: `
          formkit-invalid:border-red
          formkit-invalid:focus-within:border-secondary-900
          focus-within:border-secondary-900
          formkit-disabled:border-black-400
          formkit-disabled:bg-black-300
        `,
        input: 'hover:border-primary-200 focus:border-text-highlight focus:ring-0 transition border-grey-200 rounded py-3 px-2.5 w-full px-3 outline-none formkit-disabled:text-black-800 text-black placeholder-black-500 formkit-disabled:bg-black-300',
        options: 'flex items-center flex-wrap gap-x-2.5',
        prefixIcon: 'w-7 flex items-center justify-end self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em]',
        suffixIcon: 'w-7 pr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
      },
      'family:text': {
        inner: 'flex rounded transition hover:border-primary-200 focus:border-text-highlight border-grey-200 border',
        input: 'border-none',
      },
      button: {
        outer: '$reset',
        input: '$reset btn btn-primary',
      },
      password: {
        suffixIcon: '!pr-3',
      },
      file: {
        fileItem: 'flex w-full items-center text-gray-800 mb-1 last:mb-0',
        fileItemIcon: 'w-4 mr-2 shrink-0',
        fileList: 'flex w-full h-full text-xs items-end px-3 py-2 text-gray-400 formkit-multiple:data-[has-multiple="true"]:mb-6',
        fileName: 'break-all grow text-ellipsis',
        fileRemove: 'relative flex items-center z-[2] ml-auto text-[0px] hover:text-red pl-2 peer-data-[has-multiple=true]:text-sm peer-data-[has-multiple=true]:text-black peer-data-[has-multiple=true]:ml-3 peer-data-[has-multiple=true]:mb-2 formkit-multiple:bottom-[0.15em] formkit-multiple:pl-0 formkit-multiple:ml-0 formkit-multiple:left-[1em] formkit-multiple:formkit-prefix-icon:left-[3.75em]',
        fileRemoveIcon: 'block text-black w-4 h-4 relative z-[2]',
        inner: 'relative max-w-md p-1 cursor-pointer formkit-multiple:[&>button]:absolute border border-grey-200 rounded z-0',
        input: 'cursor-pointer text-transparent absolute top-0 right-0 left-0 bottom-0 opacity-0',
        noFiles: 'flex text-xs items-end p-3',
        noFilesIcon: 'w-4 mr-2',
      },
      select: {
        input: 'pr-10 !bg-[url(\'/assets/images/select-icon.svg\')]',
      },
      submit: {
        outer: '$reset',
        input: '$reset btn btn-primary',
      },
      checkbox: boxInput,
      radio: boxInput,
    }),
  },
  rules: {
    maxFileSize: (node: FormKitNode & { value: { file: File }[] }, args: string[]) => {
      const size = +args[0] * 1024 * 1024;
      return node.value[0].file.size <= size;
    },
  },
  messages: {
    nl: {
      validation: {
        maxFileSize({name, args}: { name: string, args: string[] }) {
          const size = +args[0] / 1024;
          return `${name} mag niet groter zijn dan ${size} MB.`;
        },
        date_before({name, args}: { name: string, args: string[] }) {
          const formattedBeforeDate = useDateHelper().formatReadable(args[0]);

          return `${name} moet voor ${formattedBeforeDate} zijn.`;
        },
      },
    },
  },
};

export default config;
