import { default as _91_46_46_46slug_93toVt5u0G8gMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/[...slug].vue?macro=true";
import { default as adressenBJsVqDcJ9CMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/bedrijfsgegevens/[company]/adressen.vue?macro=true";
import { default as index5XXo8nDpVOMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/bedrijfsgegevens/[company]/index.vue?macro=true";
import { default as medewerkersblDf2SBin6Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/bedrijfsgegevens/[company]/medewerkers.vue?macro=true";
import { default as indexypeSfV9uf8Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/bedrijfsgegevens/index.vue?macro=true";
import { default as beveiliging0yU18YwVSjMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/beveiliging.vue?macro=true";
import { default as communicatievoorkeurenUQQMxSvzKJMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/communicatievoorkeuren.vue?macro=true";
import { default as indexYcALh9dynfMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/evenementen/index.vue?macro=true";
import { default as _91factuur_93Zl3kZP0ZxiMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/facturen/[factuur].vue?macro=true";
import { default as indexOAQ1bsGOxfMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/facturen/index.vue?macro=true";
import { default as indexAQcSp96fngMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/index.vue?macro=true";
import { default as indexJS7rtsdPSQMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/ledenvoordelen/index.vue?macro=true";
import { default as incassoGTjyj1oRqaMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue?macro=true";
import { default as indexwI6EbRK2E8Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/index.vue?macro=true";
import { default as indexfNLFhOXaFCMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/lidmaatschap/index.vue?macro=true";
import { default as account4twNVVhC98Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account.vue?macro=true";
import { default as loginqYrp0bkdADMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/auth/login.vue?macro=true";
import { default as _91token_93A27OkRFNbGMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/auth/wachtwoord-instellen/[token].vue?macro=true";
import { default as _91token_93Nv5EI0d4klMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/auth/wachtwoord-vergeten/[token].vue?macro=true";
import { default as index3ZYXCDMcRmMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/auth/wachtwoord-vergeten/index.vue?macro=true";
import { default as _91slug_93X6GZFbfc2UMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders/[slug].vue?macro=true";
import { default as bestuurderstrainingen_45oldFHHxi9i9bPMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders/bestuurderstrainingen-old.vue?macro=true";
import { default as dossiers_45documentenX4QxCqkb4UMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders/dossiers-documenten.vue?macro=true";
import { default as indexGoK4qE87R0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders/index.vue?macro=true";
import { default as bestuurdersBge6WkEdEmMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders.vue?macro=true";
import { default as dashboardiJgaOWo57CMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/dashboard.vue?macro=true";
import { default as evenementenCpWcSAbsDNMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/evenementen.vue?macro=true";
import { default as indexRtMvCTPXDpMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/index.vue?macro=true";
import { default as kennisbankidjHQvh0kSMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/kennisbank.vue?macro=true";
import { default as _91id_932bsIRpKDQAMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/klanten/[id].vue?macro=true";
import { default as index9XSPNcLhhWMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/klanten/index.vue?macro=true";
import { default as afnemenrRNfUQGn5gMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/ledenvoordelen/[slug]/afnemen.vue?macro=true";
import { default as indexOZVjmY9GM0Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/ledenvoordelen/[slug]/index.vue?macro=true";
import { default as indexgu9mu8EWmuMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/ledenvoordelen/index.vue?macro=true";
import { default as ledenvoordelenodA6BnEJmbMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/ledenvoordelen.vue?macro=true";
import { default as index4wFzNLa7cWMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/lid-worden/index.vue?macro=true";
import { default as uitschrijvenDWbvfnWWjcMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/nieuwsbrief/uitschrijven.vue?macro=true";
import { default as authorizejGuy577JGyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/oauth/authorize.vue?macro=true";
import { default as stylesdwPV4xgsklMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/styles.vue?macro=true";
import { default as aangemelddIWTbfkSveMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen/[slug]/[uuid]/aangemeld.vue?macro=true";
import { default as aanmeldendAW9rsY58AMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen/[slug]/[uuid]/aanmelden.vue?macro=true";
import { default as index5MtDhRaD9QMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen/[slug]/index.vue?macro=true";
import { default as indexPBUv5kV6zhMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen/index.vue?macro=true";
import { default as trainingengCtCO8ZsuyMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen.vue?macro=true";
import { default as _91id_93GQAMm1WbMgMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/verkoopkansen/[id].vue?macro=true";
import { default as indexbUgggmKhjxMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/verkoopkansen/index.vue?macro=true";
import { default as _91product_93bN5V8qgD7aMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/[category]/[product].vue?macro=true";
import { default as indexcWhFm8yklGMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/[category]/index.vue?macro=true";
import { default as cartaSaXmrAouSMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/cart.vue?macro=true";
import { default as checkoutMUBLO1TIybMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/checkout.vue?macro=true";
import { default as confirmationjd4QAaiNnQMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/confirmation.vue?macro=true";
import { default as index6W9qNCNytNMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/index.vue?macro=true";
import { default as webshopJzu2kuoHUkMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop.vue?macro=true";
import { default as zoeken0f0zPo70CxMeta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/zoeken.vue?macro=true";
import { default as aanmeldenWQsGyvV5M6Meta } from "~/pages/trainingen/[slug]/[uuid]/aanmelden.vue?macro=true";
import { default as component_45stubnPPOkDvxB8Meta } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnPPOkDvxB8 } from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93toVt5u0G8gMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/[...slug].vue")
  },
  {
    name: account4twNVVhC98Meta?.name,
    path: "/account",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account.vue"),
    children: [
  {
    name: "account-bedrijfsgegevens-company-adressen",
    path: "bedrijfsgegevens/:company()/adressen",
    meta: adressenBJsVqDcJ9CMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/bedrijfsgegevens/[company]/adressen.vue")
  },
  {
    name: "account-bedrijfsgegevens-company",
    path: "bedrijfsgegevens/:company()",
    meta: index5XXo8nDpVOMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/bedrijfsgegevens/[company]/index.vue")
  },
  {
    name: "account-bedrijfsgegevens-company-medewerkers",
    path: "bedrijfsgegevens/:company()/medewerkers",
    meta: medewerkersblDf2SBin6Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/bedrijfsgegevens/[company]/medewerkers.vue")
  },
  {
    name: "account-bedrijfsgegevens",
    path: "bedrijfsgegevens",
    meta: indexypeSfV9uf8Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/bedrijfsgegevens/index.vue")
  },
  {
    name: "account-beveiliging",
    path: "beveiliging",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/beveiliging.vue")
  },
  {
    name: "account-communicatievoorkeuren",
    path: "communicatievoorkeuren",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/communicatievoorkeuren.vue")
  },
  {
    name: "account-evenementen",
    path: "evenementen",
    meta: indexYcALh9dynfMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/evenementen/index.vue")
  },
  {
    name: "account-facturen-factuur",
    path: "facturen/:factuur()",
    meta: _91factuur_93Zl3kZP0ZxiMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/facturen/[factuur].vue")
  },
  {
    name: "account-facturen",
    path: "facturen",
    meta: indexOAQ1bsGOxfMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/facturen/index.vue")
  },
  {
    name: "account",
    path: "",
    meta: indexAQcSp96fngMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/index.vue")
  },
  {
    name: "account-ledenvoordelen",
    path: "ledenvoordelen",
    meta: indexJS7rtsdPSQMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/ledenvoordelen/index.vue")
  },
  {
    name: "account-lidmaatschap-lidmaatschap-incasso",
    path: "lidmaatschap/:lidmaatschap()/incasso",
    meta: incassoGTjyj1oRqaMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/incasso.vue")
  },
  {
    name: "account-lidmaatschap-lidmaatschap",
    path: "lidmaatschap/:lidmaatschap()",
    meta: indexwI6EbRK2E8Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/lidmaatschap/[lidmaatschap]/index.vue")
  },
  {
    name: "account-lidmaatschap",
    path: "lidmaatschap",
    meta: indexfNLFhOXaFCMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/account/lidmaatschap/index.vue")
  }
]
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginqYrp0bkdADMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/auth/login.vue")
  },
  {
    name: "auth-wachtwoord-instellen-token",
    path: "/auth/wachtwoord-instellen/:token()",
    meta: _91token_93A27OkRFNbGMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/auth/wachtwoord-instellen/[token].vue")
  },
  {
    name: "auth-wachtwoord-vergeten-token",
    path: "/auth/wachtwoord-vergeten/:token()",
    meta: _91token_93Nv5EI0d4klMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/auth/wachtwoord-vergeten/[token].vue")
  },
  {
    name: "auth-wachtwoord-vergeten",
    path: "/auth/wachtwoord-vergeten",
    meta: index3ZYXCDMcRmMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/auth/wachtwoord-vergeten/index.vue")
  },
  {
    name: bestuurdersBge6WkEdEmMeta?.name,
    path: "/bestuurders",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders.vue"),
    children: [
  {
    name: "bestuurders-slug",
    path: ":slug()",
    meta: _91slug_93X6GZFbfc2UMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders/[slug].vue")
  },
  {
    name: "bestuurders-bestuurderstrainingen-old",
    path: "bestuurderstrainingen-old",
    meta: bestuurderstrainingen_45oldFHHxi9i9bPMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders/bestuurderstrainingen-old.vue")
  },
  {
    name: "bestuurders-dossiers-documenten",
    path: "dossiers-documenten",
    meta: dossiers_45documentenX4QxCqkb4UMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders/dossiers-documenten.vue")
  },
  {
    name: "bestuurders",
    path: "",
    meta: indexGoK4qE87R0Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/bestuurders/index.vue")
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/dashboard.vue")
  },
  {
    name: "evenementen",
    path: "/evenementen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/evenementen.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexRtMvCTPXDpMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/index.vue")
  },
  {
    name: "kennisbank",
    path: "/kennisbank",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/kennisbank.vue")
  },
  {
    name: "klanten-id",
    path: "/klanten/:id()",
    meta: _91id_932bsIRpKDQAMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/klanten/[id].vue")
  },
  {
    name: "klanten",
    path: "/klanten",
    meta: index9XSPNcLhhWMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/klanten/index.vue")
  },
  {
    name: ledenvoordelenodA6BnEJmbMeta?.name,
    path: "/ledenvoordelen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/ledenvoordelen.vue"),
    children: [
  {
    name: "ledenvoordelen-slug-afnemen",
    path: ":slug()/afnemen",
    meta: afnemenrRNfUQGn5gMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/ledenvoordelen/[slug]/afnemen.vue")
  },
  {
    name: "ledenvoordelen-slug",
    path: ":slug()",
    meta: indexOZVjmY9GM0Meta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/ledenvoordelen/[slug]/index.vue")
  },
  {
    name: "ledenvoordelen",
    path: "",
    meta: indexgu9mu8EWmuMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/ledenvoordelen/index.vue")
  }
]
  },
  {
    name: "lid-worden",
    path: "/lid-worden",
    meta: index4wFzNLa7cWMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/lid-worden/index.vue")
  },
  {
    name: "nieuwsbrief-uitschrijven",
    path: "/nieuwsbrief/uitschrijven",
    meta: uitschrijvenDWbvfnWWjcMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/nieuwsbrief/uitschrijven.vue")
  },
  {
    name: "oauth-authorize",
    path: "/oauth/authorize",
    meta: authorizejGuy577JGyMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/oauth/authorize.vue")
  },
  {
    name: "styles",
    path: "/styles",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/styles.vue")
  },
  {
    name: trainingengCtCO8ZsuyMeta?.name,
    path: "/trainingen",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen.vue"),
    children: [
  {
    name: "trainingen-slug-uuid-aangemeld",
    path: ":slug()/:uuid()/aangemeld",
    meta: aangemelddIWTbfkSveMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen/[slug]/[uuid]/aangemeld.vue")
  },
  {
    name: "trainingen-slug-uuid-aanmelden",
    path: ":slug()/:uuid()/aanmelden",
    meta: aanmeldendAW9rsY58AMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen/[slug]/[uuid]/aanmelden.vue")
  },
  {
    name: "trainingen-slug",
    path: ":slug()",
    meta: index5MtDhRaD9QMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen/[slug]/index.vue")
  },
  {
    name: "trainingen",
    path: "",
    meta: indexPBUv5kV6zhMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/trainingen/index.vue")
  }
]
  },
  {
    name: "verkoopkansen-id",
    path: "/verkoopkansen/:id()",
    meta: _91id_93GQAMm1WbMgMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/verkoopkansen/[id].vue")
  },
  {
    name: "verkoopkansen",
    path: "/verkoopkansen",
    meta: indexbUgggmKhjxMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/verkoopkansen/index.vue")
  },
  {
    name: webshopJzu2kuoHUkMeta?.name,
    path: "/webshop",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop.vue"),
    children: [
  {
    name: "webshop-category-product",
    path: ":category()/:product()",
    meta: _91product_93bN5V8qgD7aMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/[category]/[product].vue")
  },
  {
    name: "webshop-category",
    path: ":category()",
    meta: indexcWhFm8yklGMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/[category]/index.vue")
  },
  {
    name: "webshop-cart",
    path: "cart",
    meta: cartaSaXmrAouSMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/cart.vue")
  },
  {
    name: "webshop-checkout",
    path: "checkout",
    meta: checkoutMUBLO1TIybMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/checkout.vue")
  },
  {
    name: "webshop-confirmation",
    path: "confirmation",
    meta: confirmationjd4QAaiNnQMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/confirmation.vue")
  },
  {
    name: "webshop",
    path: "",
    meta: index6W9qNCNytNMeta || {},
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/webshop/index.vue")
  }
]
  },
  {
    name: "zoeken",
    path: "/zoeken",
    component: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/pages/zoeken.vue")
  },
  {
    name: "bestuurders-trainingen-slug-uuid-aanmelden",
    path: "/bestuurders/trainingen/:slug()/:uuid()/aanmelden",
    meta: aanmeldenWQsGyvV5M6Meta || {},
    component: () => import("~/pages/trainingen/[slug]/[uuid]/aanmelden.vue")
  },
  {
    name: component_45stubnPPOkDvxB8Meta?.name,
    path: "/evenementen/:pathMatch(.*)",
    component: component_45stubnPPOkDvxB8
  }
]