const fileRemove = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"/></svg>';
const euro = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16"><path stroke="#0F172A" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.5 5.17a3 3 0 1 0 0 5.66M5 7h3.5M5 9h3.5M14 8A6 6 0 1 1 2 8a6 6 0 0 1 12 0Z"/></svg>';

/**
 * Object with default FormKit icons
 * @return {{select?: string, search?: string, checkboxDecorator?: string, radioDecorator?: string}}
 */

export const formKitIcons = {
  fileRemove,
  euro,
};

