import Vue3Toastify, {toast} from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default defineNuxtPlugin((nuxtApp) => {
  const toastConfig = useRuntimeConfig().public.toast ?? {};

  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 5000,
    clearOnUrlChange: false,
    ...toastConfig,
  });

  return {
    provide: {toast},
  };
});
