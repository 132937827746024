import validate from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescri_ajizwq5ipm2jgeo7fi4l6hmfqe/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import _01_45maintenance_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/middleware/01.maintenance.global.ts";
import _02_45active_45membership_45global from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/website/middleware/02.activeMembership.global.ts";
import manifest_45route_45rule from "/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@8.57.1_ioredis@5.4.1_magicast_rtexahn7tzxmo3obrpttn4vjti/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  _01_45maintenance_45global,
  _02_45active_45membership_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "sofie-page": () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.2_@bugsnag+core@7.25.0_@unhead+vue@1.11.13_vue@3.5.13_typescr_6koewsnppszdcuba3e3fnklyna/node_modules/@bttr-devs/nuxt-layer-sofie/middleware/sofie-page.ts"),
  employee: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/core/middleware/employee.ts"),
  manager: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/core/middleware/manager.ts"),
  member: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/core/middleware/member.ts"),
  page: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/core/middleware/page.ts"),
  partner: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/core/middleware/partner.ts"),
  auth: () => import("/data/www/mijn.khn.nl/mijn-khn/releases/41/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_@ope_ajag2tvao5mln2sr2zak6swe5y/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}